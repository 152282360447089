import React, { useState, useEffect, useContext } from 'react'
import styles from './JobDocStyle.module.css'
import { useAuth0 } from '../../react-auth0-wrapper'
import 'react-html5-camera-photo/build/css/index.css'
import Paper from '@material-ui/core/Paper'
import { config } from '../../config'


const JobDoc = props => {
const { isAuthenticated, loginWithRedirect, logout, getTokenSilently, user, loading } = useAuth0();
const __ID = props.match.params.id
const [fileName, setFileName] = useState('')

  useEffect(() => {
    // Step 1 - pull URL based off of DOCS ID
    // Step 2 - decodeURL
    console.log('test1');
    console.log(__ID);
    const callApi = async () => {
        try {
        const token = await getTokenSilently();
        const response = await fetch(config.API_ENDPOINT + "/api/getDocById", {
            method: 'POST',
            headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',  // sent request
            'Accept':       'application/json'   // expected data sent back
            },
            body: JSON.stringify({jobId: __ID})
        });
        const responseData = await response.json();
        console.log(responseData.data[0])
        if(responseData.message) {
            setFileName(responseData.data[0].name)
            console.log(responseData.data[0].url)
            console.log("path: " + decodeURIComponent(responseData.data[0].url))
            window.open(decodeURIComponent(responseData.data[0].url), '_blank')
        }
        } catch (error) {
            console.error(error);
        }
    };

    callApi();
}, [getTokenSilently, props.__ID]);
    
    return (<Paper className={styles.report_innerWrapper}>
        <div>
            <h2 className={styles.report_heading}>The document below is downloading...</h2>
            <p style={{'textAlign': 'center'}}>File: {fileName}</p>
            <p style={{'textAlign': 'center'}}>If your document has not downloaded, please allow popups for this domain and/or refresh the page and try again.</p>
        </div>
    </Paper>)
}
export default JobDoc